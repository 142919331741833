import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import Web3 from 'web3'
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #5994fc;
  padding: 10px;
  font-weight: bold;
  color: var(--mono-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonBelow = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #004DAF;
  padding: 10px;
  font-weight: bold;
  color: var(--mono-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: #004DAF;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 1px solid;
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;
//   border: 4px dashed var(--secondary);

export const StyledSquareImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 1px solid;
  background-color: var(--accent);
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const StyledSpacegrayLink = styled.a`
  color: var(--spacegray-text);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    WEBSITE_STATUS: 1,
    DISPLAY_STATUS: 0,
    ANYONE_MINT: false,
    ANYONE_MINT_NUM: 1,
  });
  const netlifyEnv = {
    MAX_SUPPLY: process.env.REACT_APP_MAX_SUPPLY,
    SALE_PERIOD: process.env.REACT_APP_SALE_PERIOD,
    SUBTITLE: process.env.REACT_APP_SUBTITLE,
    WEBSITE_STATUS: process.env.REACT_APP_WEBSITE_STATUS,
    DISPLAY_STATUS: process.env.REACT_APP_DISPLAY_STATUS,
    ANYONE_MINT: process.env.REACT_APP_ANYONE_MINT,
    ANYONE_MINT_NUM: process.env.REACT_APP_ANYONE_MINT_NUM,
  };
  const websiteStatusMsg = {
    0: "The SOWTEN mint-site is coming soon! Please wait for a while.",
    1: "(Now on sale)",
    2: "The SOWTEN mint-site is under maintenance. Please wait for a while.",
    9: "The sale has ended. Thanks for following our project!!!",
  }

  const claimNFTs = () => {
    // let cost = CONFIG.WEI_COST;
    let price = data.price;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(price * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME} NFT...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong. Please read the note below carefully, and try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const maxMintAmount = () => {
    var maxMintAmount;

    if (CONFIG.ANYONE_MINT == true) {
      maxMintAmount = (CONFIG.ANYONE_MINT_NUM)
    } else if(!data.maxMintAmount || data.maxMintAmount == 0 || (data.maxMintAmount - data.mintAmount) <= 1) {
      maxMintAmount = 1
    } else {
      maxMintAmount = data.maxMintAmount - data.mintAmount;
      let maxMintAmountOnPeriod = data.maxSupplyOnPeriod - data.totalSupplyOnPeriod;
      if (maxMintAmount > maxMintAmountOnPeriod) {
        maxMintAmount = maxMintAmountOnPeriod;
      }
    }
    return maxMintAmount;
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    let _maxMintAmount = maxMintAmount();
    if (newMintAmount > _maxMintAmount) {
      newMintAmount = _maxMintAmount;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    // get Netlify Environment Variables
    if(netlifyEnv.MAX_SUPPLY) config.MAX_SUPPLY = netlifyEnv.MAX_SUPPLY;
    if(netlifyEnv.SALE_PERIOD) config.SALE_PERIOD = netlifyEnv.SALE_PERIOD;
    if(netlifyEnv.SUBTITLE) config.SUBTITLE = netlifyEnv.SUBTITLE;
    if(netlifyEnv.WEBSITE_STATUS) config.WEBSITE_STATUS = netlifyEnv.WEBSITE_STATUS;
    if(netlifyEnv.DISPLAY_STATUS) config.DISPLAY_STATUS = netlifyEnv.DISPLAY_STATUS;
    if(netlifyEnv.ANYONE_MINT) config.ANYONE_MINT = netlifyEnv.ANYONE_MINT;
    if(netlifyEnv.ANYONE_MINT_NUM) config.ANYONE_MINT_NUM = netlifyEnv.ANYONE_MINT_NUM;
    SET_CONFIG(config);
  };

  const changeMetamaskNetwork = async () => {
    if (window.ethereum.networkVersion !== CONFIG.NETWORK.ID) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(CONFIG.NETWORK.ID) }]
        });
      } catch (err) {
        console.log(err);
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          /*
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: CONFIG.NETWORK.NAME,
                chainId: web3.utils.toHex(CONFIG.NETWORK.ID),
                nativeCurrency: { name: CONFIG.NETWORK.NAME, decimals: 18, symbol: config.NETWORK.SYMBOL },
                rpcUrls: ['https://polygon-rpc.com/']
              }
            ]
          });
          */
          console.log("Can't change Network. Please change network manualy.");
        }
      }
    }
  }

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)", backgroundPositionX: "center", backgroundPositionY: "top" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        { /** Logo
        <a href={CONFIG.MARKETPLACE_LINK} target="_blank">
          <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        </a>
        */}
        <s.TextTitle
          style={{
            textAlign: "center",
            fontSize: 30,
            fontWeight: "bold",
            color: "var(--accent-text)",
            paddingTop: 250,
          }}
        >
          {CONFIG.SUBTITLE}
        </s.TextTitle>

        { /** example.gif(egg)
        <StyledSquareImg
              alt={"example"}
              src={"/config/images/example.gif"}
            />
        **/ }
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/** Left Column
            <StyledImg alt={"example"} src={"/config/images/example.gif"} />
            **/}
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={1.5}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "#111",
              padding: 24,
              /*borderRadius: 12,*/
              /*border: "1px solid var(--accent)",*/
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }
            /* border: "4px dashed var(--secondary)", 
              borderRadius: 24,            
            */
          }
          >
            {(CONFIG.WEBSITE_STATUS !== "1") ? (
              <>
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  {websiteStatusMsg[CONFIG.WEBSITE_STATUS]}
                </s.TextTitle>
                {(CONFIG.WEBSITE_STATUS == "9") ? (
                  <>
                    <s.SpacerMedium />
                    <StyledButton
                      style={{
                        margin: "5px",
                      }}
                      onClick={(e) => {
                        window.open(CONFIG.MARKETPLACE_LINK, "_blank");
                        console.log(data);
                      }}
                    >
                      {CONFIG.MARKETPLACE}
                    </StyledButton>
                  </>
                  ) : null}
              </>
            ): (
              <>
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 50,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  {(CONFIG.DISPLAY_STATUS != "9") ? (
                      <>
                      {(data.maxSupplyOnPeriod && data.maxSupplyOnPeriod != 0) ? (Number(data.totalSupplyOnPeriod)) + " / " + CONFIG.MAX_SUPPLY : "Please connect."}
                      </>
                    ) : (
                      <>
                        {" "}
                      </>
                    )
                  }
                </s.TextTitle>
                {(((data.maxSupplyOnPeriod && data.maxSupplyOnPeriod != 0))) ? (
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "var(--light-yellow-text)",
                    }}
                  >
                  ( { (data.maxMintAmount && data.maxMintAmount != 0) ? "enable mint: " + data.mintAmount + " / " + data.maxMintAmount : "Not allowed or limit reached. Thank you!"} )
                </s.TextTitle>
                ):""}
                {Number(data.totalSupplyOnPeriod) >= data.maxSupplyOnPeriod ? (
                  <>
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--secondary-text)" }}
                    >
                      The sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                      style={{ textAlign: "center", color: "var(--secondary-text)" }}
                    >
                      You can still find {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                      {CONFIG.MARKETPLACE}
                    </StyledLink>
                  </>
                ) : (
                  <>
                    {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          Connect to the {CONFIG.NETWORK.NAME} network
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <StyledButton
                          onClick={(e) => {
                            e.preventDefault();
                            changeMetamaskNetwork().then((result) => {
                              dispatch(connect()).then((result) => {
                                getData();
                              })
                            })
                          }}
                        >
                          CONNECT
                        </StyledButton>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--secondary)",
                              }}
                            >
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>
                      
                    ) : (
                      <>
                        {((data.maxMintAmount && data.maxMintAmount != 0)) ? (
                          <>
                            {(data.mintAmount < data.maxMintAmount) ? (
                              <>
                                <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--secondary)",
                                    }}
                                  >
                                    {feedback}
                                  </s.TextDescription>
                                  <s.SpacerMedium />
                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <StyledRoundButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmount();
                                      }}
                                    >
                                      -
                                    </StyledRoundButton>
                                    <s.SpacerMedium />
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                      }}
                                    >
                                      {mintAmount}
                                    </s.TextDescription>
                                    <s.SpacerMedium />
                                    <StyledRoundButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmount();
                                      }}
                                    >
                                      +
                                    </StyledRoundButton>
                                  </s.Container>
                                  <s.SpacerSmall />
                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs();
                                        getData();
                                      }}
                                    >
                                      {claimingNft ? "BUSY" : "BUY"}
                                    </StyledButton>
                                  </s.Container>
                                  <s.SpacerSmall />
                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                    <s.TextTitle
                                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                                    >
                                      1 {CONFIG.SYMBOL} costs {data.price ? blockchain.web3.utils.fromWei(String(data.price), 'ether') : "-"}{" "}
                                      {CONFIG.NETWORK.SYMBOL}.
                                    </s.TextTitle>
                                  </s.Container>
                                  <s.Container ai={"center"} jc={"center"} fd={"row"}>
                                  <s.TextDescription
                                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                                    >
                                      Excluding gas fees.
                                    </s.TextDescription>
                                  </s.Container>
                                </>
                          ) : (
                            <>
                            <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--light-yellow)",
                                }}
                              >
                              Thank you for minting SOWTEN NFT!
                            </s.TextDescription> 
                          </>
                          )}
                        </>
                        ) : null}
                      </>
                    )}
                  </>
                )}
                <s.SpacerMedium />
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                  }}
                >
                  <StyledSpacegrayLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                    Contract: {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                  </StyledSpacegrayLink>
                </s.TextDescription>
                <span
                  style={{
                    textAlign: "center",
                  }}
                >
                  <StyledButtonBelow
                    onClick={(e) => {
                    /* window.open("/config/roadmap.pdf", "_blank");*/
                      window.open(CONFIG.OFFICIAL_LINK, "_blank");
                    }}
                    style={{
                      margin: "5px",
                    }}
                  >
                    Website
                  </StyledButtonBelow>
                  <StyledButtonBelow
                    style={{
                      margin: "5px",
                    }}
                    onClick={(e) => {
                      window.open(CONFIG.MARKETPLACE_LINK, "_blank");
                      console.log(data);
                    }}
                  >
                    {CONFIG.MARKETPLACE}
                  </StyledButtonBelow>
                </span>
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            {/** Right Column
            <StyledImg
              alt={"example"}
              src={"/config/images/example.gif"}
              style={{ transform: "scaleX(-1)" }}
            />  */}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        {(CONFIG.WEBSITE_STATUS == "1") ? (
          <>
            <s.Container 
              jc={"center"} 
              ai={"center"} 
              style={{ 
                width: "70%",
                backgroundColor: "var(--spacegray)",
                padding: 24,
                borderRadius: 12,
                border: "1px solid #000",
                borderRadius: 0, 
              }}>
              <s.Container
                style={{
                  textAlign: "left",
                  color: "var(--primary-text)",
                  fontSize: "90%",
                }}
              >
                <ul>
                  <li><span style={{color: "var(--secondary)"}}>
                    Note: Once you make the purchase, you cannot undo this action.</span></li>
                  <li>Please make sure you are connected to the right network (
                {CONFIG.NETWORK.NAME}) and the correct address. </li>
                  <li>We recommend that you don't lower the gas limit.</li>
                  <li>If you are still experiencing unexplained error, please contact the Developer on <a href="https://discord.gg/sowten" target="_blank">Discord</a>.</li>
                </ul>
              </s.Container>
            </s.Container>
          </>
        ) : null }
      </s.Container>
    </s.Screen>
  );
}

export default App;
