// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      // const CONFIG = await store.getState().CONFIG;
      // TODO: ここからの記述をなんとかする
      const configResponse = await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const CONFIG = await configResponse.json();
      // get Netlify Environment Variables
      if(process.env.REACT_APP_SALE_PERIOD) CONFIG.SALE_PERIOD = process.env.REACT_APP_SALE_PERIOD;
      // TODO: ここまで
      let account = store.getState().blockchain.account;
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let price = await store
        .getState()
        .blockchain.smartContract.methods.getPriceOnPeriod(CONFIG.SALE_PERIOD)
        .call();
      let mintAmount = await store
        .getState()
        .blockchain.smartContract.methods.getMintAmountOnPeriod(account, CONFIG.SALE_PERIOD)
        .call();
      let maxMintAmount = await store
        .getState()
        .blockchain.smartContract.methods.getWhitelistUserOnPeriod(account, CONFIG.SALE_PERIOD)
        .call();
      var totalSupplyOnPeriod = await store
        .getState()
        .blockchain.smartContract.methods.getTotalSupplyOnPeriod(CONFIG.SALE_PERIOD)
        .call();
      totalSupplyOnPeriod = Number(totalSupplyOnPeriod) + 39;
      let maxSupplyOnPeriod = await store
        .getState()
        .blockchain.smartContract.methods.getMaxSupplyOnPeriod(CONFIG.SALE_PERIOD)
        .call();
      // let anyoneCanMint = await store
      //   .getState()
      //   .blockchain.smartContract.methods.getAnyoneCanMint(CONFIG.SALE_PERIOD)
      //   .call();
      // anyoneCanMint
      if(process.env.REACT_APP_ANYONE_MINT) CONFIG.ANYONE_MINT = process.env.REACT_APP_ANYONE_MINT;
      if(process.env.REACT_APP_ANYONE_MINT_NUM) CONFIG.ANYONE_MINT_NUM = process.env.REACT_APP_ANYONE_MINT_NUM;
      if(CONFIG.ANYONE_MINT && CONFIG.ANYONE_MINT == "true") {
        if(CONFIG.ANYONE_MINT_NUM) {
          maxMintAmount = CONFIG.ANYONE_MINT_NUM;
        }
      }

      dispatch(
        fetchDataSuccess({
          totalSupply,
          price,
          mintAmount,
          maxMintAmount,
          totalSupplyOnPeriod,
          maxSupplyOnPeriod,
          // anyoneCanMint,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
